.Contact {
    &-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(20, 31, 34, 0.8);
        backdrop-filter: blur(4px);
        z-index: 1;
        cursor: pointer;
    }
    &-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 80%;
        max-width: 500px;
        min-height: 500px;

        box-sizing: border-box;
        border: 1px solid #657565;
        transform: translate(-50%, -50%);
        background: #141F22;
        padding: 50px;
        z-index: 2;
        h1 {
            font-weight: normal;
            cursor: pointer;
            text-align: right;
        }
        &-methods {
            margin-top: 50px;
            // display: flex;
            // justify-content: space-between;

            a {
                display: block;
                font-size: 24px;
                margin-bottom: 20px;
            }
        }

        @media only screen and (max-width: 600px) {
            padding: 25px;
            max-width: 300px;
            min-height: 300px;
        }
    }
    &-exit {
    }
}
